import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { TestimonialV2 } from "../components/testimonialV1";

const BackgroundSection = ({ className, children }) => {
  const { heroimage } = useStaticQuery(
    graphql`
      query {
        heroimage: file(relativePath: { eq: "hero-desk-shot-v3-color.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  // Set ImageData.
  const imageData = heroimage.childImageSharp.fluid;

  return (
    <BackgroundImage Tag="div" className={className} fluid={imageData}>
      {children}
    </BackgroundImage>
  );
};

const About = () => {
  return (
    <Layout headerClass="relative bg-white" bodyClass="px-0 md:px-0 lg:px-0">
      <SEO title="About Me" />
      <div className="min-h-screen h-full flex flex-col">
        <BackgroundSection className="w-full h-40 sm:h-32 lg:h-56">
          <div className="container mx-auto px-4 sm:px-2 md:px-20 lg:px-24 mb-24">
            <div className="w-7/12 mt-10 sm:mt-12 lg:mt-20 font-heading self-start">
              <h1 className="md:text-3xl lg:text-6xl text-4xl  text-pink-600 leading-tight text-shadow-xl">
                About Me
              </h1>
            </div>
          </div>
        </BackgroundSection>
        <div className="container mx-auto pb-10  md:px-20 lg:px-56 px-6">
          <div className="flex flex-col mt-5 mb-5">
            <div className="md:text-2xl lg:text-4xl text-3xl">
              <h2 className="font-heading text-teal-900">Background</h2>
            </div>
          </div>
          <div>
            <div className="mb-2 text-base self-center m-4">
              <p>
                Angela has over 20 years’ experience working in global business
                roles in Asia, America and Europe within the high-growth,
                transformational Technology sector and has worked with such
                diverse sectors as Life Sciences, Media, Communications,
                Manufacturing and state organisations.
              </p>
            </div>
          </div>
          <div>
            <div className="mb-2 text-base self-center m-4">
              <p>
                She has also had career transitions into NGO’s and consultancy,
                which along with her career in Tech, has grounded her coaching
                focus in the alignment of her client’s goals with impactful
                business and personal outcomes by applying a behavioural and
                mindset perspective to individual and team performance and
                development.
              </p>
            </div>
          </div>
          <div>
            <div className="mb-2 text-base self-center m-4">
              <p>
                Along with a BA honours in Business, specialising in Human
                Resources, from Dublin City University, Angela has
                qualifications in Executive Coaching, Leadership and
                Organizational Behaviour, Negotiating/Sales Performance and
                Personal Leadership/Influencing. Angela is also professionally
                accredited with the European Mentoring and Coaching Council
                (EMCC) at Senior Practitioner level and is an EMCC Volunteer.
              </p>
            </div>
          </div>
        </div>

        <div className="container mx-auto pb-10 md:px-20 lg:px-56 px-6 bg-teal-700 heropattern-texture-teal-700 ">
          <div className="flex flex-col mt-5 mb-5">
            <div className="md:text-2xl lg:text-4xl text-3xl">
              <h2 className="font-heading text-purple-900">
                My Coaching History
              </h2>
            </div>
          </div>
          <div>
            <div className="mb-2 text-base self-center m-4">
              <p>
                I have been coaching and mentoring individuals and teams
                throughout my career, unlocking business performance by focusing
                on motivating change to maximise each individual’s potential
                development and success.
              </p>
            </div>
          </div>
          <div className="p-1 bg-purple-900 heropattern-texture-purple-900">
            <div className="m-4">
              <p className="text-white text-shadow-sm text-center">
                Personally, I’ve experienced first-hand the impact of coaching
                to create opportunities and shifts in perspective that trigger
                change. This is why I’m inspired to support my clients to unlock
                the spark that motivates change, development and growth for
                them.
              </p>
            </div>
          </div>
        </div>
        <div className="container mx-auto px-6 md:px-10 lg:px-16 mb-5 ">
          <div className="flex flex-col mt-5 mb-5">
            <div className="text-4xl  self-center">
              <h3 className="font-heading text-teal-900">Testimonials</h3>
            </div>
            <div className="flex flex-col -mx-2 overflow-hidden sm:-mx-2 md:-mx-2 lg:-mx-5 xl:-mx-5 items-center">
              <TestimonialV2
                fullName="Damian Hanrahan"
                jobTitle="Territory Manager"
                companyName="Financial Sector"
                testimonialText="I found my sessions with Angela to be extremely beneficial. She helped me to establish a clear plan for my needs. During the process she was easy to talk to, she listened intently and she shared excellent insights."
              />
              <TestimonialV2
                fullName="Cédric Wulf"
                jobTitle="Account Manager"
                companyName="Hospitality Sector"
                testimonialText="Our sessions made me come into work with a different mindset."
              />
              <TestimonialV2
                fullName="Joanne Laffan"
                jobTitle="Senior Leadership Team"
                companyName="Life Sciences Research Sector"
                testimonialText="I found our sessions thought provoking, enjoyable and challenging.  During the intervening days I would reflect upon the questions Angela posed, as we gradually worked through each step moving me away from my stuck, over saturated narratives to deeper clarity and understanding.  It was liberating at the end of our sessions to realize that I had accomplished my goal.  "
              />
              <TestimonialV2
                fullName="Natalia Geller"
                jobTitle="Director EMEA"
                companyName="Tech Sector"
                testimonialText="I really enjoyed working with Angela as each session was a chapter in a book that we were writing together. Best to say, I was envisioning my own future with Angela's most tactful yet so powerful coaching. Angela creates such a safe and friendly environment that it becomes easy to share thoughts, brainstorm, and work together.
                          She truly cares about you and your goals, she makes you have a look at your situation from interesting perspectives.  For me, each session was a valuable investment into my own future.  Angela is a professional and gifted coach, highly recommended."
              />
              <TestimonialV2
                fullName="Danielle Salvatore"
                jobTitle="Sales Manager EMEA"
                companyName="Tech Sector"
                testimonialText="Angela really helped me see my career goals clearly, driving my focus on what was important and where I wanted to go.  Angela's approach forced me to enact critical-thinking across a range of challenges and obstacles without even realizing what was happening at the time.  In contrast to any standard theory-based approach, Angela enabled me to make my own career decisions and there are a number of practical elements of Angela’s coaching that I have stuck with to this day.   Working with Angela has given me the tools and confidence to proactively chase my ambitions."
              />
              <TestimonialV2
                fullName="Donna Duffy"
                jobTitle="Account Executive"
                companyName="Professional Services Sector"
                testimonialText="I found coaching with Angela of great help: definitely helped me realise what I was interested in, what skills I could build on and what direction I could take. I would definitely recommend it to anyone who is unsure of their career path."
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
